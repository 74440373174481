<template>
  <div class="page page--offset page__edit-document">
    <esmp-tabs :animated="false" v-model="activeTab">
      <template v-if="activeTab === 'construct'" #extra>
        <div
          class="edit-document__right-bar"
        >
          <div class="edit-document__search">
            <esmp-input
              class="edit-document__search-field"
              label="Поиск по контролам"
              size="small"
              icon="search"
              clearable
              v-model="elementSearchQuery"
              @enter-exact="searchElement"
              @button-click="searchElement"
              @focusout="searchFocusOut"
              @cleared="searchFocusOut"
            />
          </div>
          <div @click="toggleView" class="edit-document__expander">
            <esmp-icon :name="expandAll ? 'arrow-up' : 'arrow-down'" />
            <span class="edit-document__expander-text">
              {{ expandAll ? 'Скрыть' : 'Раскрыть' }} все контролы
            </span>
          </div>
        </div>
      </template>
      <esmp-tab-pane
        label="Конструктор"
        name="construct"
      >
        <service-form
          v-model="filteredElementList"
          :controls="controlList"
          :is-searching="isSearching"
        />
      </esmp-tab-pane>
      <esmp-tab-pane
        label="Настройки формы"
        name="setting"
      >
        <div class="setting-form-table-wrapper">
          <table class="setting-form-table">
            <tr>
              <td>Название шаблона</td>
              <td>
                <esmp-input v-model="dto.name" />
              </td>
            </tr>
            <tr>
              <td>Комментарий</td>
              <td>
                <esmp-input :options="{ type: 'textarea' }" v-model="dto.comment" />
              </td>
            </tr>
          </table>
        </div>
      </esmp-tab-pane>
    </esmp-tabs>
    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        class="page__action-button"
        @click="save(false)"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        class="page__action-button"
        @click="save(true)"
        view="outline"
      >
        Сохранить и выйти
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import Hub from '@/plugins/event-hub';
import {
  mappedControls,
  setOrder,
  setTechId,
  filterElementsBySearch,
  flatElements,
} from '@/helpers/controls';
import ServiceForm from '@/components/service-form';

export default {
  name: 'EditDocument',
  components: {
    ServiceForm,
  },
  data() {
    return {
      activeTab: 'construct',
      controlList: [],
      elementList: [],
      dto: {
        name: '',
        comment: '',
        formItems: [],
      },
      expandAll: false,
      filterElementList: [],
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('controlSearch', ['query', 'isSearching']),
    elementSearchQuery: {
      get() {
        return this.query;
      },
      set(value) {
        this.setQuery(value);
      },
    },
    flattedElements() {
      return flatElements(this.elementList);
    },
    filteredElementList: {
      get() {
        if (this.isSearching) {
          return this.filterElementList;
        }
        return this.elementList;
      },
      set(updatedElementList) {
        this.elementList = updatedElementList;
      },
    },
    isCreateMode() {
      return this.$route.name === 'CreateDocument';
    },
    isEditMode() {
      return this.$route.name === 'EditDocument';
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('controlSearch', ['setQuery', 'setIsSearching']),
    toggleView() {
      this.expandAll = !this.expandAll;
      Hub.$emit('toggle-views', this.expandAll);
    },
    async save(hasRedirect) {
      if (!this.dto.name) {
        this.$EsmpNotify.$show('Название шаблона не заполнено', 'error');
      } else {
        this.dto.formItems = setOrder(cloneDeep(this.elementList));

        const saveMethod = this.isCreateMode ? 'createDocument' : 'updateDocument';
        this.setLoading({ key: 'page', value: true });
        this.$API.documents[saveMethod](this.dto)
          .then(({ data }) => {
            this.$EsmpNotify.$show('Документ успешно сохранен', 'success');
            if (hasRedirect) {
              this.$router.push({ name: 'Documents' });
            } else {
              if (this.isCreateMode) {
                this.$router.push({ name: 'EditDocument', params: { id: data.id } });
              }
              this.setForm(data);
            }
          })
          .finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
      }
    },
    getControls() {
      return this.$API.documents.getControls().then(({ data }) => {
        this.controlList = mappedControls(data) || [];
      });
    },
    getDocument(id) {
      return this.$API.documents.getDocument(id).then(({ data }) => this.setForm(data));
    },
    setForm(data) {
      this.dto = data;
      this.elementList = setTechId(data.formItems);
    },
    async searchElement() {
      this.setLoading({ key: 'page', value: true });
      await this.$nextTick();
      await this.setIsSearching(!!this.elementSearchQuery.length);
      // eslint-disable-next-line max-len
      this.filterElementList = this.flattedElements.filter((element) => filterElementsBySearch(element, this.elementSearchQuery));
      await this.$nextTick();
      this.setLoading({ key: 'page', value: false });
    },
    async searchFocusOut() {
      if (!this.query && this.isSearching) {
        this.setLoading({ key: 'page', value: true });
        await this.$nextTick();
        this.filterElementList = [];
        this.setIsSearching(false);
        await this.$nextTick();
        this.setLoading({ key: 'page', value: false });
      }
    },
    async init() {
      if (this.isEditMode) {
        await this.getDocument(this.$route.params.id);
      }
    },
  },
  created() {
    this.setLoading({ key: 'page', value: true });
    Promise
      .all([
        this.getControls(),
        this.init(),
      ])
      .finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
  },
};
</script>
<style lang="scss">
.page__edit-document {
  padding-bottom: $gap * 2;

  td {
    padding: $base-gutter / 2;
    width: 350px;
    &:first-child {
      text-align: right;
      color: $color-grayscale-60;
    }
  }

  textarea.esmp-input-element {
    height: 100px;
  }

  .setting-form-table-wrapper {
    padding: $base-gutter;
    background: $color-white;
    border-radius: $base-gutter;
  }
}

.edit-document {
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: $base-gutter / 2;
    width: 200px;
    &:hover {
      cursor: pointer;
    }
  }
  &__expander-text {
    display: inline-block;
    margin-bottom: -4px;
  }
  .esmp-input-element {
    background-color: $color-white;
  }
}
</style>
